
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { FiltersScopes } from '@/services/filters/types';
import { FilterSortingTypes } from '@/components/Op/Filters/types';
import { FilterGroup, Filter } from '@/views/Offers/OfferFilters/types';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import OpFilterElement from '@/components/Op/Filters/OpFilterElement.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { OpFilterElement, PwrCardTitle, PwrCard, PwrBtn }
})
export default class OpFilter extends Vue {
  @Prop({ required: true }) filterGroup!: FilterGroup;
  @Prop({ default: () => 5 }) maxItemsOnPage!: number;
  @Prop({ default: () => 10 }) maxItemsOnPageExpanded!: number;
  @Prop() filtersScopes!: FiltersScopes;

  // Sorters

  private cmpItemsDesc(a: Filter, b: Filter): number {
    return b.count - a.count; // DESC
  }

  private cmpItemsAsc(a: Filter, b: Filter): number {
    return a.count - b.count; // ASC
  }

  private cmpAsIs(a: Filter, b: Filter): number {
    return a.key - b.key; // Should result in backend enum order
  }

  private cmpAlphabeticalDesc(a: Filter, b: Filter): number {
    if (a.label < b.label) {
      return 1;
    }
    if (a.label > b.label) {
      return -1;
    }
    return 0;
  }

  private cmpAlphabeticalAsc(a: Filter, b: Filter): number {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  get filters(): Filter[] {
    switch (this.filterGroup.sorting) {
      case FilterSortingTypes.ALPHABETICAL_ASC:
        return this.filterGroup.filters.sort(this.cmpAlphabeticalAsc);

      case FilterSortingTypes.ALPHABETICAL_DESC:
        return this.filterGroup.filters.sort(this.cmpAlphabeticalDesc);

      case FilterSortingTypes.ITEMS_ASC:
        return this.filterGroup.filters.sort(this.cmpItemsAsc);

      case FilterSortingTypes.ITEMS_DESC:
        return this.filterGroup.filters.sort(this.cmpItemsDesc);

      default:
        return this.filterGroup.filters.sort(this.cmpAsIs);
    }
  }

  @Watch('selected', { deep: true })
  private onSelectedChange(): void {
    this.$emit('change', this.filterGroup.key, this.selected);
  }

  private selected: string[] = [];
  private itemHeight = 32 + 14;

  public clearFilters(): void {
    this.selected = [];
  }

  get itemsCount(): number {
    return this.filterGroup.filters.length;
  }

  get itemsHeight(): number {
    if (this.itemsCount > this.maxItemsOnPage) {
      return this.maxItemsOnPage * this.itemHeight;
    }

    return this.itemsCount * this.itemHeight;
  }

  get filtersStyle() {
    return `overflow:auto; max-height:${this.itemHeight * this.maxItemsOnPageExpanded}px`;
  }
}
