import { FiltersScopes } from '@/services/filters/types';
import Offer, { dictFields } from '@/models/Offer';

export default class FiltersService {
  public static getFiltersScope(offers: Offer[], offerDictionaryKeys: string[]): FiltersScopes {
    const scopes: FiltersScopes = {};
    const tmpFilters: { [key: string]: number[] } = {};

    // Get all filters based on offers
    offers.forEach((offer: Offer) => {
      dictFields.forEach((filterableFiledKey: keyof Offer) => {
        const filterableFiledValue = offer[filterableFiledKey] as number;

        if (filterableFiledValue && offerDictionaryKeys.includes(filterableFiledKey)) {
          if (!(filterableFiledKey in tmpFilters)) {
            tmpFilters[filterableFiledKey] = [];
            scopes[filterableFiledKey] = {};
          }

          tmpFilters[filterableFiledKey].push(filterableFiledValue);
        }
      });
    });

    // Calculate count for any given filter
    Object.keys(scopes).forEach((filterName: string) => {
      const filters = tmpFilters[filterName];

      filters.forEach((filter: number) => {
        const filterAsKey = filter.toString();

        if (!(filterAsKey in scopes[filterName])) {
          scopes[filterName][filterAsKey] = 1;
        } else {
          scopes[filterName][filterAsKey] += 1;
        }
      });
    });

    return scopes;
  }
}
