
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { debounce } from 'lodash';
import { Dictionary } from '@/types';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes
} from '@/store/offers/types';
import { FilterGroup } from '@/views/Offers/OfferFilters/types';
import { FilterSortingTypes } from '@/components/Op/Filters/types';
import { FiltersScopes } from '@/services/filters/types';
import Offer from '@/models/Offer';
import FiltersService from '@/services/filters/FiltersService';
import PwrVue from '@/components/PwrVue';
import { ParsedDictionary, DictionaryOfDictionaries } from '@/modules/core/core-dynamic-dictionaries';
import OpFilter from '@/components/Op/Filters/OpFilter.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import OpOffersSkeletonLoader from '@/components/Op/Offers/OpOffersSkeletonLoader.vue';
import OpFiltersSkeletonLoader from '@/components/Op/Filters/OpFiltersSkeletonLoader.vue';

const offersNamespace = 'offers';

@Component({
  components: {
    OpFiltersSkeletonLoader,
    OpOffersSkeletonLoader,
    OpFilter,
    PwrCardTitle,
    PwrCard,
    PwrBtn
  }
})
export default class OfferFilters extends PwrVue {
  @Prop() loading!: boolean | string;
  @Prop({ default: () => ['state', 'attributes'] }) forbiddenFilters!: string[];
  @Prop({ default: () => ({ 'city': FilterSortingTypes.ALPHABETICAL_DESC }) })
  sortingBindings!: Dictionary<FilterSortingTypes>;

  @Getter(OffersGetterTypes.GET_PROCESSED_BY_QUERY, { namespace: offersNamespace })
  offersProcessedByQuery!: Offer[];

  @Action(OffersActionTypes.FILTER, { namespace: offersNamespace })
  filterOffers!: (filters: Dictionary<string[]> | undefined) => void;

  $refs!: {
    offerFilters: OpFilter[];
  };

  private debounceFiltersUpdate = debounce(this.filterOffersDebounceTarget, 500);

  private selectedFilters: Dictionary<string[]> = {};
  private expand = false;

  private filterOffersDebounceTarget(): void {
    this.filterOffers(this.selectedFilters);

    this.$emit('filtersUpdateEnd');
  }

  private offerFiltersCount = 0;

  get offerFilters(): FilterGroup[] {
    const filtersGroups: FilterGroup[] = [];
    const dicts: DictionaryOfDictionaries = this.dictionaries.offer;

    if (!dicts) {
      return [];
    }

    Object.keys(dicts).forEach((dictName: string) => {
      if (!this.forbiddenFilters.includes(dictName)) {
        const dictHandler: ParsedDictionary = dicts[dictName];

        let sortType = FilterSortingTypes.ITEMS_DESC;

        if (this.sortingBindings && this.sortingBindings[dictName] >= 0) {
          sortType = this.sortingBindings[dictName];
        }

        const group: FilterGroup = {
          filters: [],
          sorting: sortType,
          name: dictHandler.name,
          key: dictName
        };

        if (this.scope && Object.keys(this.scope).includes(dictName)) {
          const allowedItems = Object.keys(this.scope[dictName]);

          allowedItems.forEach((itemKey: string) => {
            const value = dictHandler.labels[itemKey];

            if (value) {
              group.filters.push({
                label: value,
                key: Number(itemKey),
                count: this.scope[dictName][itemKey]
              });
            }
          });
        } else {
          Object.keys(dicts[dictName].labels).forEach((labelKey: string) => {
            const value = dictHandler.labels[labelKey];

            if (value) {
              group.filters.push({
                label: value,
                key: Number(labelKey),
                count: 0
              });
            }
          });
        }

        if (group.filters.length) {
          filtersGroups.push(group);
        }
      }
    });

    this.offerFiltersCount = filtersGroups.length;

    return filtersGroups;
  }

  private clearFilters(): void {
    this.$refs.offerFilters?.forEach((filterElement: OpFilter) => {
      filterElement.clearFilters();
    });
  }

  private updateFilters(filterKey: string, items: string[]): void {
    this.$emit('filtersUpdateBegin');

    this.selectedFilters[filterKey] = items;

    if (!items.length) {
      delete this.selectedFilters[filterKey];
    }

    this.debounceFiltersUpdate();
  }

  @Watch('offersProcessedByQuery', { deep: true })
  private onOffersProcessedByQuery(): void {
    this.clearFilters();
  }

  /**
   * Finds out what filters are available based on elements all available offers
   * (def value of offersProcessedByQuery) or subset based on query string.
   */
  get scope(): FiltersScopes {
    if (this.dictionaries && this.offersProcessedByQuery.length) {
      const availableFilters = Object.keys(this.dictionaries.offer);
      return FiltersService.getFiltersScope(this.offersProcessedByQuery, availableFilters);
    }

    return {};
  }

  private bottomExpandButton() {
    this.expand = !this.expand;

    if (!this.expand) {
      this.$vuetify.goTo('body', {
        duration: 600,
        easing: 'easeInOutCubic'
      });
    }
  }
}
