
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class OpFilterElement extends PwrVue {
  @Prop({ required: true }) label!: string;

  private checkbox = true;
}
